import './editor.scss';
import './style.scss';

const { __ } = wp.i18n;
const { InspectorControls } = wp.blockEditor;
const { registerBlockType } = wp.blocks;
const { withSelect } = wp.data;
const {
	PanelBody,
	RangeControl,
	TextControl,
	ToggleControl,
	SelectControl,
} = wp.components;
const { decodeEntities } = wp.htmlEntities;
const { createElement, Fragment } = wp.element;

registerBlockType('pixelbound/case-studies-grid', {
	title: __('Case Studies Grid', 'pixelbound-case-studies'),
	icon: 'grid-view',
	keywords: [
		__('Block', 'pixelbound-case-studies'),
		__('Posts', 'pixelbound-case-studies'),
	],
	attributes: {
		imageAspectRatio: {
			type: 'string',
			default: '1:1',
		},
		caseStudyCategories: {
			type: 'boolean',
			default: true,
		},
		caseStudyExcerpt: {
			type: 'boolean',
			default: true,
		},
		caseStudyLink: {
			type: 'boolean',
			default: true,
		},
		caseStudyLinkLabel: {
			type: 'string',
			default: __('Find Out More', 'pixelbound-case-studies'),
		},
		numberOfCaseStudiesPerPage: {
			type: 'number',
			default: 6,
		},
		caseStudyColumnCount: {
			type: 'number',
			default: 3,
		},
		spacingTop: {
			type: 'number',
			default: 4,
		},
		spacingBottom: {
			type: 'number',
			default: 4,
		},
	},
	edit: withSelect((select, props) => {
		const { attributes } = props;
		const { numberOfCaseStudiesPerPage } = attributes;

		const caseStudies = select('core').getEntityRecords(
			'postType',
			'pb_case_study',
			{
				per_page: numberOfCaseStudiesPerPage,
				orderby: 'date',
				order: 'desc',
			}
		);

		const caseStudiesToReturn =
			caseStudies &&
			caseStudies.map((caseStudy) => {
				let categoriesInfo = [];
				let featuredMediaDetails = null;

				// If the post has a featured image then let's get it and add it to out post object
				if (caseStudy.featured_media) {
					featuredMediaDetails = select('core').getMedia(
						caseStudy.featured_media
					);
				}

				// If the post is assigned to any categories then let's get them and add them to the post object
				if (caseStudy.categories) {
					const categories = select('core').getEntityRecords(
						'taxonomy',
						'category',
						{
							per_page: -1,
						}
					);

					if (categories) {
						caseStudy.categories.forEach((categoryID) => {
							categories.forEach((category) => {
								if (category.id === categoryID) {
									categoriesInfo.push({
										id: category.id,
										name: category.name,
										url: category.link,
									});
								}
							});
						});
					}
				}

				return { ...caseStudy, featuredMediaDetails, categoriesInfo };
			});

		return {
			caseStudies: caseStudiesToReturn,
		};
	})((props) => {
		const { caseStudies, className, attributes, setAttributes } = props;
		const {
			imageAspectRatio,
			caseStudyCategories,
			caseStudyExcerpt,
			caseStudyLink,
			caseStudyLinkLabel,
			numberOfCaseStudiesPerPage,
			caseStudyColumnCount,
			spacingTop,
			spacingBottom,
		} = attributes;

		// Construct inline styles object
		let inlineStyles = {};
		spacingTop ? (inlineStyles.marginTop = `${spacingTop}em`) : '0em';
		spacingBottom ? (inlineStyles.marginBottom = `${spacingBottom}em`) : '0em';

		return (
			<Fragment>
				<InspectorControls>
					<PanelBody
						title={__('Settings', 'pixelbound-case-studies')}
						initialOpen={true}
					>
						<SelectControl
							label={__('Image Aspect Ratio', 'pixelbound')}
							options={[
								{
									label: __('Ratio 1:1', 'pixelbound-case-studies'),
									value: '1:1',
								},
								{
									label: __('Ratio 4:3', 'pixelbound-case-studies'),
									value: '4:3',
								},
								{
									label: __('Ratio 5:4', 'pixelbound-case-studies'),
									value: '5:4',
								},
								{
									label: __('Ratio 16:9', 'pixelbound-case-studies'),
									value: '16:9',
								},
							]}
							value={imageAspectRatio}
							onChange={(value) => setAttributes({ imageAspectRatio: value })}
						/>
						<ToggleControl
							label={__(
								'Toggle Case Study Categories',
								'pixelbound-case-studies'
							)}
							help={
								caseStudyCategories
									? __(
											'Disable the case study categories',
											'pixelbound-case-studies'
									  )
									: __(
											'Enable the case study categories',
											'pixelbound-case-studies'
									  )
							}
							checked={caseStudyCategories}
							onChange={(caseStudyCategories) =>
								setAttributes({ caseStudyCategories })
							}
						/>
						<ToggleControl
							label={__('Toggle Case Study Excerpt', 'pixelbound-case-studies')}
							help={
								caseStudyExcerpt
									? __(
											'Disable the case study excerpt',
											'pixelbound-case-studies'
									  )
									: __(
											'Enable the case study excerpt',
											'pixelbound-case-studies'
									  )
							}
							checked={caseStudyExcerpt}
							onChange={(caseStudyExcerpt) =>
								setAttributes({ caseStudyExcerpt })
							}
						/>
						<ToggleControl
							label={__('Toggle Case Study Link', 'pixelbound-case-studies')}
							help={
								caseStudyLink
									? __('Disable the case study link', 'pixelbound-case-studies')
									: __('Enable the case study link', 'pixelbound-case-studies')
							}
							checked={caseStudyLink}
							onChange={(caseStudyLink) => setAttributes({ caseStudyLink })}
						/>
						<TextControl
							label={__('Case Study Button Label', 'pixelbound-case-studies')}
							help={__(
								'Change the text that appears in the "Find Out More" buttons.',
								'pixelbound-case-studies'
							)}
							value={
								caseStudyLinkLabel
									? caseStudyLinkLabel
									: __('Find Out More', 'pixelbound-case-studies')
							}
							onChange={(caseStudyLinkLabel) =>
								setAttributes({ caseStudyLinkLabel })
							}
						/>
						<hr />
						<RangeControl
							label={__('Number Of Case Studies', 'pixelbound-case-studies')}
							help={__(
								'How many case studies should be included in the carousel.',
								'pixelbound-case-studies'
							)}
							value={numberOfCaseStudiesPerPage}
							onChange={(value) =>
								setAttributes({ numberOfCaseStudiesPerPage: value })
							}
							step={1}
							min={1}
							max={48}
						/>
						<RangeControl
							label={__('Number of Columns', 'pixelbound-case-studies')}
							help={__('How many columns should this grid have?')}
							value={caseStudyColumnCount}
							onChange={(value) =>
								setAttributes({ caseStudyColumnCount: value })
							}
							step={1}
							min={1}
							max={6}
						/>
						<RangeControl
							label={__('Spacing Top', 'pixelbound-case-studies')}
							value={spacingTop}
							onChange={(value) => setAttributes({ spacingTop: value })}
							step={0.1}
							min={0}
							max={20}
						/>
						<RangeControl
							label={__('Spacing Bottom', 'pixelbound-case-studies')}
							value={spacingBottom}
							onChange={(value) => setAttributes({ spacingBottom: value })}
							step={0.1}
							min={0}
							max={20}
						/>
					</PanelBody>
				</InspectorControls>

				{caseStudies && caseStudies.length > 0 ? (
					<div
						className={`${className} has-${caseStudyColumnCount}-columns`}
						style={inlineStyles}
					>
						{caseStudies.map((caseStudy) => (
							<article
								className={'wp-block-pixelbound-case-study'}
								key={caseStudy.id}
							>
								<div className={'wp-block-pixelbound-case-study__inner'}>
									{caseStudy.featuredMediaDetails && (
										<div
											className={`wp-block-pixelbound-case-study__image ratio-${imageAspectRatio}`}
										>
											<a
												href={caseStudy.link}
												target="_blank"
												rel="noopener noreferrer"
												style={{
													backgroundImage: `url( ${caseStudy.featuredMediaDetails.source_url})`,
												}}
											></a>
										</div>
									)}

									<h2 className={'wp-block-pixelbound-case-study__title'}>
										<a
											href={caseStudy.link}
											target="_blank"
											rel="noopener noreferrer"
										>
											{decodeEntities(caseStudy.title.rendered)}
										</a>
									</h2>
									{caseStudyCategories && caseStudy.categoriesInfo && (
										<div className={'wp-block-pixelbound-case-study__meta'}>
											<ul
												className={'wp-block-pixelbound-case-study__categories'}
											>
												{caseStudy.categoriesInfo.map((category) => (
													<li>
														<a
															href={category.url}
															target="_blank"
															rel="noopener noreferrer"
														>
															{category.name}
														</a>
													</li>
												))}
											</ul>
										</div>
									)}
									{caseStudyExcerpt && caseStudy.excerpt && (
										<div className={'wp-block-pixelbound-case-study__excerpt'}>
											{caseStudy.excerpt.raw}
										</div>
									)}
									{caseStudyLink && caseStudy.link && (
										<a
											className={'wp-block-pixelbound-case-study__link'}
											href={caseStudy.link}
											target="_blank"
											rel="noopener noreferrer"
										>
											{caseStudyLinkLabel
												? caseStudyLinkLabel
												: __('Find Out More', 'pixelbound-case-studies')}
										</a>
									)}
								</div>
							</article>
						))}
					</div>
				) : (
					<div>
						{caseStudies
							? __('No case studies to display.', 'pixelbound-case-studies')
							: __('Loading...', 'pixelbound-case-studies')}
					</div>
				)}
			</Fragment>
		);
	}),
	save: () => {
		return '';
	},
});
